import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { ToastNotification } from "@src/Components/ToastNotification";
import { useNetworkCtx } from "@src/Hooks/networkCtx";
import { useOrgCtx } from "@src/Hooks/orgCtx";

export function useSelectNetwork() {
  const orgCtx = useOrgCtx();
  const networkCtx = useNetworkCtx();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const match = pathname.match(/app\/(?<org>[^/]+)\/mpn\/(?<network>[^/]+)\/(?<path>.*)/);

  const selectNetwork = useCallback(
    (network: string) => {
      if (pathname.includes("network/add") || pathname.includes(`network/${networkCtx}/edit`)) {
        navigate(`/app/${orgCtx}/mpn/${network}/`);
      } else if (match) {
        navigate(`/app/${orgCtx}/mpn/${network}/${match?.groups["path"]}`);
      }
      toast.success(<ToastNotification title={`Switched network`} />);
    },
    [navigate, match, pathname, orgCtx, networkCtx]
  );

  return {
    selectNetwork
  };
}
