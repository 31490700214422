import axios from "axios";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { useMutation as useReactQueryMutation } from "react-query";
import { NavLink, useNavigate } from "react-router-dom";

import GraphQLErrors from "@src/Components/GraphQLErrors";
import { Loading } from "@src/Components/Loading/Loading";
import { ModalCloseOnButton } from "@src/Components/Modal/Modal";
import { H2, H3 } from "@src/Components/Text";
import { ChartConfigField } from "@src/generated/graphql";
import { useIsAdmin } from "@src/Hooks/isAdmin";
import { useOrgCtx } from "@src/Hooks/orgCtx";
import { useToggle } from "@src/Hooks/toggle";

import { configFieldsSort } from "../Infrastructure/ResourceChartSelector";
import { QueryCallbacks } from "../mpnRequests";
import {
  NetworkForm,
  NetworkFormInner,
  serialiseNetworkForm,
  SuccessModal
} from "./NetworkFormComponents";
import { useLatestNetworkRegistrationChart } from "./networkRegistrationChart";

function initConfigFields(fields: ChartConfigField[]) {
  return [...(fields || [])].sort(configFieldsSort).map(field => {
    const { type, value, required } = field;
    return {
      ...field,
      type: field.label === "infraSiteId" ? "site-selector" : field.type,
      value: type === "boolean" ? value === "true" : "",
      initialValue: value,
      open: true,
      selected: required
    };
  });
}

function createNewNetwork(network: object, { onComplete }: QueryCallbacks) {
  return axios
    .post("/mobile-networks/network/tmf-api/resourceInventoryManagement/v4/resource", network)
    .then(response => {
      onComplete();
      return response.data;
    });
}

export function NewNetworkForm() {
  const navigate = useNavigate();
  const orgCtx = useOrgCtx();
  const { state: showSuccess, toggle: toggleSuccess } = useToggle();

  const { chart, loading, error: infraChartError } = useLatestNetworkRegistrationChart();

  const {
    data,
    error: newNetworkError,
    mutate: createNetwork
  } = useReactQueryMutation("create-network", (newNetwork: object) =>
    createNewNetwork(newNetwork, {
      onComplete: toggleSuccess
    })
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (loading) return <Loading />;
  if (!chart) return <NoChartMessage />;
  if (infraChartError)
    return (
      <GraphQLErrors
        submitErrors={infraChartError?.graphQLErrors}
        titleMessage="Something happened while fetching for Infrastructure Block Charts"
      />
    );

  const initialValues: NetworkForm = {
    id: "The Eventual Id of the Network",
    fields: initConfigFields(chart?.config || [])
  };

  return (
    <>
      <Formik<NetworkForm>
        initialValues={initialValues}
        onSubmit={(values, formik) => {
          try {
            const serialisedValues = serialiseNetworkForm(values, chart.version, orgCtx);
            createNetwork(serialisedValues);
            formik.setSubmitting(false);
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {formikProps =>
          formikProps.isSubmitting ? (
            <Loading />
          ) : (
            <>
              <NetworkFormInner
                goBack={goBack}
                submitErrors={newNetworkError as Error}
                {...formikProps}
              />
              <ModalCloseOnButton show={showSuccess}>
                <SuccessModal id={data?.id} />
              </ModalCloseOnButton>
            </>
          )
        }
      </Formik>
    </>
  );
}

function NoChartMessage() {
  const orgCtx = useOrgCtx();
  const { isAdmin } = useIsAdmin();
  return (
    <>
      <H2>Could not find an Infrastructure Block Chart</H2>
      <H3>
        No Chart able to create a Network could be found in the currently set Provisions Repository.{" "}
        {isAdmin ? (
          <>
            Go to the{" "}
            <NavLink to={`/app/${orgCtx}/admin/customizations`}>Customization Page</NavLink> and set
            the correct Provisions Repository.
          </>
        ) : (
          <>Contact your System Admin to set the correct Provisions Repository.</>
        )}
      </H3>
    </>
  );
}
