import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useDefaultOrg } from "./Hooks/defaultOrg";

export function OrgRedirect() {
  const { pathname } = useLocation();

  const { org } = useDefaultOrg();
  if (!org) return null;

  const dest = destination(pathname, org);
  return <Navigate to={dest} replace />;
}

function destination(path: string, org: string) {
  if (org == "*") return "/app/admin";

  switch (path) {
    case "/app/service-designer":
      return `/app/${org}/services/service-designer`;
    case "/app/publisher":
      return `/app/${org}/services/publisher`;
    case "/app/infrastructure":
      return `/app/${org}/services/infrastructure`;
    case "/app/reports":
      return `/app/${org}/services/reports`;
    case "/app/settings":
      return `/app/${org}/admin/`;
    case "/app/services":
    default:
      return `/app/${org}/services/overview`;
  }
}
