import { useLegacyPermissionCheck } from "./permissionsCheck";

export function useServicesDashboard() {
  const { allowed, loading } = useLegacyPermissionCheck({
    relation: "view",
    objectType: "dashboards",
    objectId: "services"
  });

  return { allowed, loading };
}
