import { UseSelectStateChange } from "downshift";
import React, { useCallback } from "react";
import styled from "styled-components";

import { useLazyQuery, useQuery } from "@apollo/client";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { Reload } from "@src/Components/Buttons/Reload";
import { Dropdown } from "@src/Components/Dropdown";
import { Tooltip } from "@src/Components/Tooltip";
import { ConfigField } from "@src/Devices/CloudResource/cloudResourceUtils";
import {
  ChartConfigField,
  ChartKey,
  FetchInfraBlockChartQuery,
  FetchInfraBlockChartQueryVariables,
  FetchInfraChartListQuery,
  FetchInfraChartListQueryVariables,
  InfraChartType
} from "@src/generated/graphql";
import { useShortUUID } from "@src/Hooks/shortUUID";
import FetchInfraBlockChart from "@src/MobilePrivateNetwork/Network/FetchInfraBlockChart.graphql";
import FetchInfraChartList from "@src/MobilePrivateNetwork/Network/FetchInfraChartList.graphql";

const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20%;
`;
const DropdownWrapper = styled.div`
  width: 100%;
`;
const RefreshContainer = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  grid-row: 2;
`;

const ReloadCharts = styled(Reload)`
  cursor: inherit;
`;

export function configFieldsSort(a: ChartConfigField, b: ChartConfigField) {
  if (a.label === "name") return -1;
  if (b.label === "name") return 1;
  if (a.label === "infraSiteId") return -1;
  if (b.label === "infraSiteId") return 1;
  if (a.label === "location") return -1;
  if (b.label === "location") return 1;
  return 0;
}

function initConfigFields(fields: ChartConfigField[]) {
  return [...(fields || [])].sort(configFieldsSort).map(field => {
    const { type, value, required } = field;
    return {
      ...field,
      type: field.label === "location" ? "location-selector" : field.type,
      value: type === "boolean" ? value === "true" : "",
      initialValue: value,
      open: true,
      selected: required
    };
  });
}

interface ResourceChartSelectorProps {
  setFieldValue: (field: string, value: ChartKey | ConfigField[]) => void;
}

export function ResourceChartSelector({ setFieldValue }: ResourceChartSelectorProps) {
  const { data, loading, refetch } = useQuery<
    FetchInfraChartListQuery,
    FetchInfraChartListQueryVariables
  >(FetchInfraChartList, {
    variables: {
      type: InfraChartType.MnCore
    },
    notifyOnNetworkStatusChange: true
  });

  const tooltipId = useShortUUID();

  const setChart = useCallback(
    (chart: ChartKey) => {
      setFieldValue("chart", chart);
      if (!chart) setFieldValue("config", []);
    },
    [setFieldValue]
  );

  const setConfig = useCallback(
    (data: FetchInfraBlockChartQuery) => {
      const config = initConfigFields(data?.infraBlockChart?.config);
      setFieldValue("config", config);
    },
    [setFieldValue]
  );

  const [fetchChartConfig] = useLazyQuery<
    FetchInfraBlockChartQuery,
    FetchInfraBlockChartQueryVariables
  >(FetchInfraBlockChart, {
    onCompleted: setConfig
  });

  const onSelect = useCallback(
    (changes: UseSelectStateChange<ChartKey>) => {
      const chart = changes.selectedItem;
      setChart(chart);
      fetchChartConfig({
        variables: {
          name: chart.name,
          version: chart.version,
          type: InfraChartType.MnCore
        }
      });
    },
    [setChart, fetchChartConfig]
  );

  const getChartData = useCallback((item: ChartKey) => {
    if (!item) return null;
    return `${item?.name} - ${item?.version}`;
  }, []);

  const refresh = useCallback(() => {
    refetch();
  }, [refetch]);
  return (
    <SelectorWrapper>
      <DropdownWrapper>
        <Dropdown
          loading={loading}
          items={data?.infraBlockCharts || []}
          placeholder="Select an available provider"
          getItemDisplayName={getChartData}
          getItemKey={getChartData}
          onSelectedItemChange={onSelect}
        />
      </DropdownWrapper>
      <RefreshContainer>
        <Clickable
          onClick={refresh}
          id={`refresh-resource-providers-${tooltipId.current}`}
          disabled={loading}
        >
          <ReloadCharts />
        </Clickable>
        <Tooltip
          selectorId={`refresh-resource-providers-${tooltipId.current}`}
          place="right"
          offset={0}
        >
          Refresh list of providers
        </Tooltip>
      </RefreshContainer>
    </SelectorWrapper>
  );
}
