import { useOrgCtx } from "./orgCtx";
import { useLegacyPermissionCheck } from "./permissionsCheck";

export function useIsMno(defaultOrg?: string) {
  const orgId = useOrgCtx();

  const { allowed, loading } = useLegacyPermissionCheck({
    relation: "mno",
    objectType: "orgs",
    objectId: defaultOrg || orgId
  });

  return { isMno: allowed, loading };
}
