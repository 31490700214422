export function useCurrentDashboard(pathname: string) {
  const isMPN = pathname.split("/")[3] === "mpn";
  const isAdminDashboard = pathname.split("/").includes("admin");

  const noAccess =
    pathname.split("/")[2]?.includes("no-dashboard") || pathname.split("/")[2]?.includes("no-org");

  return {
    isServices: !isMPN && !isAdminDashboard && !noAccess,
    isMPN,
    isAdminDashboard
  };
}
