import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useOrgCtx } from "@src/Hooks/orgCtx";
import { PermissionArgs, useLegacyPermissionCheck } from "@src/Hooks/permissionsCheck";

type ProtectedRouteProps = {
  permissions: PermissionArgs;
  children?: ReactNode;
  redirectPath?: string;
};

export function ProtectedRoute({ permissions, redirectPath = "/", children }: ProtectedRouteProps) {
  const { allowed } = useLegacyPermissionCheck(permissions);

  if (!allowed && allowed != null) {
    return <Navigate to={redirectPath} replace />;
  }

  return allowed == null ? <></> : <>{children}</>;
}

type SettingsProtectedRoute = {
  relation: string;
  redirectPath?: string;
  children: ReactNode;
};

export function SettingsProtectedRoute({
  relation,
  children,
  redirectPath = "/"
}: SettingsProtectedRoute) {
  const orgCtx = useOrgCtx();
  const perms = orgCtx
    ? { relation, objectId: orgCtx, objectType: "orgs" }
    : { relation: "superAdmin", objectId: "nearbyone", objectType: "envs" };

  return (
    <ProtectedRoute permissions={perms} redirectPath={redirectPath}>
      {children}
    </ProtectedRoute>
  );
}

type ProtectedDashboard = {
  dashboard: string;
  redirectPath?: string;
  children: ReactNode;
};

export function ProtectedDashboard({
  dashboard,
  children,
  redirectPath = "/"
}: ProtectedDashboard) {
  const perms = { relation: "view", objectId: dashboard, objectType: "dashboards" };
  return (
    <ProtectedRoute permissions={perms} redirectPath={redirectPath}>
      {children}
    </ProtectedRoute>
  );
}
