import React, { Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

import AboutIcon from "../img/info-solid.svg";
import { AdminNavbar } from "./AdminDashboard/AdminNavbar";
import { DelayedRedirect } from "./Components/DelayedRedirect";
import { LoadingPage } from "./Components/Loading/LoadingPage";
import {
  Img,
  LoggedOutWarning,
  Logo,
  Main,
  Page,
  Sidebar
} from "./Components/Navigation/NavbarStyles";
import { NavItem } from "./Components/Navigation/NavItem";
import { ErrorBoundary } from "./ErrorBoundary";
import { Header } from "./Header";
import { useBrandingLogos } from "./Hooks/brandingLogos";
import { useCurrentDashboard } from "./Hooks/useCurrentDashboard";
import { LogsPanel } from "./LogsPanel/LogsPanel";
import { MPNNavbar } from "./MobilePrivateNetwork/MPNNavbar";
import { ServicesNavbar } from "./ServicesNavbar";
import { useSession } from "./Session";

const AboutLink = styled.div`
  margin-top: auto;
`;

export function Layout() {
  const { isLoggedOut } = useSession();
  const { pathname } = useLocation();

  const logoUrl = useBrandingLogos("nav");

  const { isServices, isMPN, isAdminDashboard } = useCurrentDashboard(pathname);

  return (
    <Page>
      <Sidebar>
        <Logo to="/">
          <Img src={logoUrl} alt="logo" />
        </Logo>
        {isServices && <ServicesNavbar />}
        {isMPN && <MPNNavbar />}
        {isAdminDashboard && <AdminNavbar />}
        <AboutLink>
          <NavItem linkTo="/app/about" label="About" icon={AboutIcon} />
        </AboutLink>
      </Sidebar>
      <Header />
      <Main>
        {isLoggedOut ? (
          <>
            <LoggedOutWarning>You are not logged in. Redirecting to login page...</LoggedOutWarning>
            <DelayedRedirect delay={5000} to="/auth/login" />
          </>
        ) : null}
        <ErrorBoundary pathname={pathname}>
          <Suspense fallback={<LoadingPage />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </Main>
      {isServices && <LogsPanel />}
    </Page>
  );
}
