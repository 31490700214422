import React, { useCallback } from "react";
import styled from "styled-components";

import Warning from "@img/exclamation-triangle-solid.svg";
import Normal from "@img/info-solid.svg";
import Logs from "@img/logs.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { Tag } from "@src/Components/StatusTag";
import { AuditLogEventType } from "@src/generated/graphql";
import { ReportLog } from "@src/Reports/ReportsLogsTable";

const LogsFilterWrapper = styled.div<{ $padding?: string }>`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  background-color: white;
  padding: ${({ $padding }) => $padding};
`;

export const logTypeFilters = Object.values(AuditLogEventType).filter(
  type => type !== AuditLogEventType.Unknown
);

interface LogsFilterProps {
  toggleFilterTag: (field: string) => void;
  selectedFilters: string[];
  logs: ReportLog[];
  filtersPadding?: string;
}

export function LogsFilter({
  toggleFilterTag,
  selectedFilters,
  logs,
  filtersPadding
}: LogsFilterProps) {
  return (
    <LogsFilterWrapper $padding={filtersPadding}>
      <LogFilterBadge
        type="All"
        toggleFilterTag={toggleFilterTag}
        selected={selectedFilters.length === logTypeFilters.length}
        quantity={logs.length}
      />
      {logTypeFilters.map(type => (
        <LogFilterBadge
          key={type}
          type={type}
          toggleFilterTag={toggleFilterTag}
          selected={selectedFilters.includes(type)}
          quantity={logs.filter(l => l.type === type).length}
        />
      ))}
    </LogsFilterWrapper>
  );
}

const EventTypeTag = styled(Tag)<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.grey};
  background-color: ${({ theme, $selected }) => $selected && theme.backgroundLight};
  &:hover {
    filter: brightness(95%);
  }
`;

const NormalLogIcon = styled(Normal)`
  height: 12px;
  color: ${({ theme }) => theme.lightblue};
`;

const WarningIcon = styled(Warning)`
  height: 12px;
  color: ${({ theme }) => theme.warning};
`;
const LogsIcon = styled(Logs)`
  height: 14px;
  fill: #747474;
`;
const ClickableFilter = styled(Clickable)`
  padding: 0;
`;

type TagTypes = AuditLogEventType | "All";

interface LogFilterBadgeProps {
  toggleFilterTag: (field: string) => void;
  type: TagTypes;
  selected: boolean;
  quantity: number;
}

function LogFilterBadge({ toggleFilterTag, type, selected, quantity }: LogFilterBadgeProps) {
  const toggleFilter = useCallback(() => {
    toggleFilterTag(type === "All" ? "ALL" : type);
  }, [toggleFilterTag, type]);

  return (
    <ClickableFilter onClick={toggleFilter}>
      <EventTypeTag $selected={selected}>
        <FilterIcons type={type} />
        {type} ({quantity})
      </EventTypeTag>
    </ClickableFilter>
  );
}

function FilterIcons({ type }: { type: TagTypes }) {
  switch (type) {
    case AuditLogEventType.Normal:
      return <NormalLogIcon>ⓘ</NormalLogIcon>;
    case AuditLogEventType.Warning:
      return <WarningIcon />;
    case "All":
      return <LogsIcon />;
    default:
      return;
  }
}
