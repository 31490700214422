import React from "react";

import CompletionsIcon from "@img/completions.svg";
import CustomizationIcon from "@img/customizations.svg";
import GroupsIcon from "@img/groups.svg";
import MapIcon from "@img/map.svg";
import OrgIcon from "@img/org.svg";
import UserIcon from "@img/user-filled.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useIsSuperAdmin } from "@src/Hooks/isSuperAdmin";
import { useOrgCtx } from "@src/Hooks/orgCtx";

export function AdminNavbar() {
  const { isSuperAdmin } = useIsSuperAdmin();
  const orgCtx = useOrgCtx();
  const rootPath = isSuperAdmin ? "/app/admin/" : `/app/${orgCtx}/admin/`;

  return (
    <NavIcons>
      <NavItem
        linkTo={rootPath + "map-settings"}
        label="Map Settings"
        icon={MapIcon}
        disabled={isSuperAdmin}
      />
      <NavItem linkTo={rootPath + "completions"} label="Completions" icon={CompletionsIcon} />
      <NavItem
        linkTo={rootPath + "customizations"}
        label="Customization"
        icon={CustomizationIcon}
      />
      <NavItem linkTo={rootPath + "users"} label="Users" icon={UserIcon} />
      <NavItem
        linkTo={rootPath + "groups"}
        label="Groups"
        icon={GroupsIcon}
        disabled={!isSuperAdmin}
      />
      <NavItem linkTo={rootPath + "organizations"} label="Organizations" icon={OrgIcon} />
    </NavIcons>
  );
}
