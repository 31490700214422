import React from "react";

import { OrgSelector } from "@src/Components/OrgSelector";
import { useIsSuperAdmin } from "@src/Hooks/isSuperAdmin";
import { useOrgCtx } from "@src/Hooks/orgCtx";
import { useSelectOrg } from "@src/Hooks/selectOrg";
import { useUserOrgs } from "@src/Hooks/userOrgs";

import { HeaderItem } from "./HeaderStyles";

export const HeaderOrgSelector = () => {
  const orgCtx = useOrgCtx();
  const { isSuperAdmin } = useIsSuperAdmin();
  const { selectOrg } = useSelectOrg();
  const { userOrgs, loading } = useUserOrgs();

  if (!orgCtx || loading || isSuperAdmin || isSuperAdmin == null) return null;

  const loadingOrgs = !(orgCtx && userOrgs);

  return (
    <HeaderItem>
      Org:
      <OrgSelector
        selectedOrg={orgCtx}
        organizations={userOrgs}
        selectOrg={selectOrg}
        loading={loadingOrgs}
      />
    </HeaderItem>
  );
};
