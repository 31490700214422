import React, { ReactNode } from "react";
import { PlacesType, Tooltip as TooltipComponent } from "react-tooltip";

type TooltipProps = {
  selectorId: string;
  place?: PlacesType;
  clickable?: boolean;
  offset?: number;
  children?: ReactNode;
};

export function Tooltip({ selectorId, place, clickable, children, offset }: TooltipProps) {
  return (
    <TooltipComponent
      anchorSelect={`#${selectorId}`}
      place={place}
      clickable={clickable}
      variant="light"
      offset={offset}
      style={{
        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.3)",
        zIndex: 1
      }}
    >
      {children}
    </TooltipComponent>
  );
}
