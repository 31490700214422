import React, { MouseEvent, useCallback } from "react";
import { css, styled } from "styled-components";

import { useQuery } from "@apollo/client";
import DotIcon from "@img/dot.svg";
import { Arrow } from "@src/Components//arrowStyles";
import {
  Org,
  Role,
  SiteTreeItemQuery,
  SiteTreeItemQueryVariables,
  SiteTreeOrgQuery,
  SiteTreeOrgQueryVariables
} from "@src/generated/graphql";
import { useIsAdmin } from "@src/Hooks/isAdmin";
import { useOrgCtx } from "@src/Hooks/orgCtx";
import { NavigateSiteItem, SiteTreeProps } from "@src/Hooks/siteTree";
import { useUserRole } from "@src/Hooks/userRole";

import { SiteTree } from "../serialise";
import { NewChildSiteInput, NewSiteInput } from "./NewSiteInput";
import { SiteTreeItem } from "./SiteTreeItem.graphql";
import { SiteTreeOrg } from "./SiteTreeOrg.graphql";
import { SiteTreeSkeleton } from "./SiteTreeSkeleton";

export const ScrollBarStyle = css`
  //firefox
  scrollbar-width: thin;
  scrollbar-color: #5a5a5a #e9e9e9;
  //others
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #e9e9e9;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #5a5a5a;
  }
`;

export const StyledTree = styled.div`
  line-height: 1.7;
  height: 100%;
  font-size: 16px;
  overflow: auto;
  ${ScrollBarStyle};
`;

interface SiteTreeComponentProps extends SiteTreeProps {
  readOnly?: boolean;
}

export function SiteTree({ readOnly, ...siteTreeProps }: SiteTreeComponentProps) {
  const orgId = useOrgCtx();
  const { data, loading } = useQuery<SiteTreeOrgQuery, SiteTreeOrgQueryVariables>(SiteTreeOrg, {
    variables: { id: orgId },
    skip: !orgId
  });

  const org = data?.org;

  return loading ? (
    <SiteTreeSkeleton />
  ) : (
    <StyledTree>
      {org ? (
        <OrgItem key={org.id} org={org} readOnly={readOnly} {...siteTreeProps} />
      ) : (
        <>Error loading Organization. Please refresh the page</>
      )}
    </StyledTree>
  );
}

export const Collapsible = styled.div<{ $isOpen: boolean }>`
  margin-left: 13px;
  height: ${({ $isOpen }) => ($isOpen ? "auto" : 0)};
  overflow: hidden;
  border-left: 2px dotted #ccc;
`;

const SelectedStyledItem = css`
  background-color: ${({ theme }) => theme.backgroundLight};
  color: ${({ theme }) => theme.primary};
`;

export const StyledItem = styled.div<{ $selected?: boolean }>`
  display: flex;
  gap: 2px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  ${({ $selected }) => ($selected ? SelectedStyledItem : null)}

  &:hover {
    background-color: ${({ $selected }) => ($selected ? "#f0f0f0" : "#f9f9f9")};
  }
`;
const Dot = styled(DotIcon)`
  height: auto;
  width: 10px;
  padding: 0 10px;
`;

interface OrgItemProps extends SiteTreeProps {
  org: Org;
  readOnly: boolean;
}

function OrgItem({ org, readOnly, ...siteTreeProps }: OrgItemProps) {
  const { selectTreeItem, toggleSite, state } = siteTreeProps;

  const { id: orgId } = org;

  const clickToggle = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      toggleSite(orgId);
    },
    [orgId, toggleSite]
  );

  const selected = state.selected === orgId;
  const expanded = state.expanded[orgId];
  const { isAdmin } = useIsAdmin();
  const { userRole } = useUserRole();
  const emptySites = org?.sites.length === 0;
  const renderNewSite =
    !readOnly && (selected || (expanded && emptySites)) && (isAdmin || userRole === Role.Operator);
  const showArrow = !readOnly || !emptySites;

  return (
    <>
      <StyledItem onClick={() => selectTreeItem(orgId, [], {})} $selected={selected}>
        {showArrow ? (
          <Arrow onClick={clickToggle} $expanded={expanded}>
            <span />
          </Arrow>
        ) : (
          <Dot />
        )}
        {org?.displayName}
      </StyledItem>
      <Collapsible $isOpen={state.expanded[orgId]}>
        {(org?.sites || []).map(
          s =>
            s && (
              <SiteItem
                key={s.id}
                siteId={s.id}
                ancestors={[orgId]}
                readOnly={readOnly}
                {...siteTreeProps}
              />
            )
        )}
        {renderNewSite && <NewSiteInput ancestors={[orgId]} {...siteTreeProps} />}
      </Collapsible>
    </>
  );
}

interface SiteItemProps extends SiteTreeProps {
  siteId: string;
  readOnly: boolean;
  ancestors: string[];
}

export function SiteItem({ siteId, readOnly, ancestors, ...siteTreeProps }: SiteItemProps) {
  const { selectTreeItem, toggleSite, state } = siteTreeProps;

  const { data } = useQuery<SiteTreeItemQuery, SiteTreeItemQueryVariables>(SiteTreeItem, {
    variables: { id: siteId }
  });

  const clickToggle = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      toggleSite(siteId);
    },
    [siteId, toggleSite]
  );

  const selected = state.selected === siteId;
  const expanded = state.expanded[siteId];
  const { isAdmin } = useIsAdmin();
  const { userRole } = useUserRole();
  const emptySites = data?.site?.sites.length === 0;
  const renderNewSite =
    !readOnly && (selected || (expanded && emptySites)) && (isAdmin || userRole === Role.Operator);
  const showArrow = !readOnly || !emptySites;

  const navItem: NavigateSiteItem = {
    siteId: data?.site.id
  };

  return (
    <>
      <StyledItem onClick={() => selectTreeItem(siteId, ancestors, navItem)} $selected={selected}>
        {showArrow ? (
          <Arrow onClick={clickToggle} $expanded={expanded}>
            <span />
          </Arrow>
        ) : (
          <Dot />
        )}
        {data?.site?.displayName}
      </StyledItem>
      <Collapsible $isOpen={state.expanded[siteId]}>
        {(data?.site.sites || []).map(
          s =>
            s && (
              <SiteItem
                key={s.id}
                siteId={s.id}
                ancestors={[...ancestors, siteId]}
                readOnly={readOnly}
                {...siteTreeProps}
              />
            )
        )}
        {renderNewSite && (
          <NewChildSiteInput ancestors={ancestors.concat(siteId)} {...siteTreeProps} />
        )}
      </Collapsible>
    </>
  );
}
