import React from "react";
import { useLocation } from "react-router";

import NetworkIcon from "@img/network.svg";

import PublisherIcon from "../img/cloud-arrow-up-solid.svg";
import FederationIcon from "../img/federation.svg";
import ServiceDesignerIcon from "../img/project-diagram-solid.svg";
import ReportIcon from "../img/reports.svg";
import ServicesIcon from "../img/services.svg";
import { NavIcons } from "./Components/Navigation/NavbarStyles";
import { NavItem } from "./Components/Navigation/NavItem";
import { useDefaultOrg } from "./Hooks/defaultOrg";
import { useIsAdmin } from "./Hooks/isAdmin";
import { useIsMno } from "./Hooks/isMno";
import { useOrgCtx } from "./Hooks/orgCtx";

export function ServicesNavbar() {
  const { pathname } = useLocation();

  const orgCtx = useOrgCtx();
  const { org: defaultOrg } = useDefaultOrg();

  const { isMno } = useIsMno(orgCtx || defaultOrg);
  const { isAdmin } = useIsAdmin(orgCtx || defaultOrg);

  const rootPath =
    pathname === "/app/user/settings" ? `/app/${defaultOrg}/services/` : `/app/${orgCtx}/services/`;
  return (
    <>
      <NavIcons>
        <NavItem linkTo={rootPath + "overview"} label="Services" icon={ServicesIcon} />
        <NavItem
          linkTo={rootPath + "service-designer"}
          label="Designer"
          icon={ServiceDesignerIcon}
        />
        <NavItem
          disabled={!isAdmin}
          linkTo={rootPath + "publisher"}
          label="Publisher"
          icon={PublisherIcon}
        />
        <NavItem linkTo={rootPath + "infrastructure"} label="Infrastructure" icon={NetworkIcon} />
        <NavItem linkTo={rootPath + "reports"} label="Reports" icon={ReportIcon} />
        <NavItem
          disabled={!isMno}
          linkTo={rootPath + "federation"}
          label="Federations"
          icon={FederationIcon}
        />
      </NavIcons>
    </>
  );
}
