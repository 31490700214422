import { useOrgCtx } from "./orgCtx";
import { useLegacyPermissionCheck } from "./permissionsCheck";

export function useIsAdmin(defaultOrg?: string) {
  const orgId = useOrgCtx();

  const { allowed, loading } = useLegacyPermissionCheck({
    relation: "admin",
    objectType: "orgs",
    objectId: defaultOrg || orgId
  });

  return { isAdmin: allowed, loading };
}
