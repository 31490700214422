import { useSession } from "@src/Session";

export function useDefaultOrg() {
  const { session } = useSession();

  const identity = session?.identity;
  const traits = identity?.traits as { defaultOrg?: string };
  const meta = identity?.metadata_public as { org?: string };

  return {
    org: traits?.defaultOrg || meta?.org || null,
    loading: !session
  };
}
