import React from "react";
import { Navigate } from "react-router-dom";

import { useDefaultOrg } from "./Hooks/defaultOrg";
import { useIsSuperAdmin } from "./Hooks/isSuperAdmin";

export function InitialRedirect() {
  const { org, loading } = useDefaultOrg();
  const { isSuperAdmin } = useIsSuperAdmin();

  if (isSuperAdmin == null) {
    return null;
  }

  if (!loading && !org) return <Navigate to="/app/no-org" />;

  return <Navigate to={isSuperAdmin ? "/app/admin" : `app/${org}`} />;
}
