import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { ToastNotification } from "@src/Components/ToastNotification";

export function useSelectOrg() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const match = pathname.match(/app\/(?<org>[^/]+)\/(?<path>.*)/);

  const selectOrg = useCallback(
    (org: string) => {
      if (match) {
        navigate(`/app/${org}/${match?.groups["path"]}`);
        toast.success(<ToastNotification title={`Switched org`} />);
      }
    },
    [navigate, match]
  );

  return {
    selectOrg
  };
}
