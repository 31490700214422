import * as Yup from "yup";

import { ReviewFieldType } from "@src/Components/Review/FieldReview";
import {
  ChartKey,
  CloudResourceConfigFieldInput,
  LatLng,
  ValuesField
} from "@src/generated/graphql";

import { FieldInfo } from "./RenderedInputs";

export interface ConfigField extends Omit<ValuesField, "value" | "__typename"> {
  value: ValueType;
  readonly initialValue: ValueType;
  open: boolean;
  selected: boolean;
}

export type ValueType = string | boolean | number | object;

export interface ProvisionCloudResourceValues {
  displayName: string;
  site: string;
  position: {
    lat: number;
    lng: number;
  };
  controllerSite: string;
  chart: ChartKey;
  config: ConfigField[];
}

export interface EditCloudResourceDeviceValues {
  displayName: string;
  config: ConfigField[];
  position: {
    lat: number;
    lng: number;
  };
}

export function customValidation(value: ValueType, ctx: Yup.TestContext) {
  const { selected, required, label, type } = ctx.parent as ConfigField;
  if (!selected) return true;

  if (required && (value == null || value === ""))
    return ctx.createError({ message: `${label} is a required field` });

  if (type === "number" || type === "integer") {
    const invalidCharacters = ["e", "-", "+"];
    if (invalidCharacters.some(char => String(value).includes(char)))
      return ctx.createError({ message: `${label} must be a valid non negative number` });
  }

  return true;
}

const sharedCloudResourceValues = {
  displayName: Yup.string().required("display name is required"),
  config: Yup.array().of(
    Yup.object<CloudResourceConfigFieldInput>().shape({
      label: Yup.string().required(),
      value: Yup.mixed().test("field-valid", customValidation),
      required: Yup.boolean(),
      redacted: Yup.boolean(),
      open: Yup.boolean(),
      initialValue: Yup.mixed().nullable()
    })
  ),
  position: Yup.object<LatLng>().shape({
    lat: Yup.number().label("latitude").required("latitude is required"),
    lng: Yup.number().label("longitude").required("longitude is required")
  })
};

export const addCloudResourceSchema = Yup.object<ProvisionCloudResourceValues>().shape({
  ...sharedCloudResourceValues,
  site: Yup.string().required(),
  controllerSite: Yup.string().required(),
  chart: Yup.object<ChartKey>().shape({
    name: Yup.string().required(),
    version: Yup.string().required()
  })
});

export const updateCloudResourceSchema =
  Yup.object<EditCloudResourceDeviceValues>().shape(sharedCloudResourceValues);

export function initFieldsNewDevice(fields: Omit<ValuesField, "__typename">[]): ConfigField[] {
  return (fields || []).map(field => {
    const { type, value, required } = field;

    return {
      ...field,
      value: type === "boolean" ? value === "true" : "",
      initialValue: value,
      open: true,
      selected: required
    };
  });
}

export function initFieldsEditDevice(fields: ValuesField[]): ConfigField[] {
  return (fields || []).map(field => {
    const { type, value, redacted, required } = field;

    return {
      ...field,
      value: type === "boolean" ? value === "true" : value || "",
      initialValue: value,
      open: !redacted,
      selected: required && !redacted
    };
  });
}

export function compareValueState(fieldInfo: Partial<FieldInfo>) {
  const { initialValue, value } = fieldInfo;
  const isDefaultUnsetValue = initialValue == null && (value === "" || value === false);
  return String(initialValue) === String(value) || isDefaultUnsetValue;
}

export function serialiseReviewFields(config: ConfigField[], isEdit: boolean) {
  const searialisedConfig = config.map(field => {
    const { value, initialValue, selected } = field;
    let reviewDisplayValue;
    let reviewType: ReviewFieldType;
    if (selected && !compareValueState(field)) {
      reviewDisplayValue = value === "" ? "❛❛ ❜❜" : value;
      reviewType = isEdit ? "modified" : "override";
    } else if (!selected && initialValue == null) {
      reviewDisplayValue = undefined;
      reviewType = "unset";
    } else {
      reviewDisplayValue = initialValue === "" ? "❛❛ ❜❜" : initialValue;
      reviewType = isEdit ? "unmodified" : "default";
    }
    return {
      ...field,
      reviewType,
      value: reviewDisplayValue
    };
  });
  return searialisedConfig;
}
