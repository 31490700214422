import { useQuery } from "@apollo/client";
import { OrgNameQuery, OrgNameQueryVariables } from "@src/generated/graphql";
import OrgName from "@src/User/OrgName.graphql";

import { useOrgCtx } from "./orgCtx";

export function useOrgInfo(fetchName = false) {
  const orgId = useOrgCtx();

  const { data, loading } = useQuery<OrgNameQuery, OrgNameQueryVariables>(OrgName, {
    variables: { id: orgId },
    skip: !orgId || !fetchName
  });

  return {
    orgId,
    orgName: data?.org?.displayName,
    loading
  };
}
