import React from "react";
import { Navigate } from "react-router-dom";

import { useIsMno } from "./Hooks/isMno";
import { useMPNDashboard } from "./Hooks/mpnDashboard";
import { useServicesDashboard } from "./Hooks/servicesDashboard";

export function DefaultPageRedirect() {
  const { isMno } = useIsMno();

  const { allowed: allowMPN } = useMPNDashboard();
  const { allowed: allowServices } = useServicesDashboard();

  if (isMno == null || allowMPN == null || allowServices == null) return null;

  const redirectString = allowServices
    ? isMno
      ? "services/federation"
      : "services/overview"
    : allowMPN
    ? "mpn"
    : "no-dashboard";

  return <Navigate to={redirectString} />;
}
