import React from "react";
import { Outlet } from "react-router-dom";

import { ApolloProvider as Provider } from "@apollo/client";

import { getApolloClient } from "./apolloClient";
import { useOrgCtx } from "./Hooks/orgCtx";

export function ApolloProviderWithOrgCtx() {
  const orgCtx = useOrgCtx();
  const apolloClient = getApolloClient(orgCtx);

  return (
    <Provider client={apolloClient}>
      <Outlet />
    </Provider>
  );
}
