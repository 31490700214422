export const headerNetworksKey = "header-network-fetch";

export interface QueryCallbacks<T = object> {
  onComplete?: (data?: T) => void;
  onError?: (error: Error) => void;
}

export interface MPNRequestParams {
  path: string;
  orgId?: string;
  networkId?: string;
  coreId?: string;
}

export function mpnRequestUrl({ path, orgId, networkId, coreId }: MPNRequestParams) {
  const requestUrl = new URL(path, window.location.origin);

  if (orgId)
    requestUrl.searchParams.append(
      "filter",
      `relatedParty[?(@.@referredType==Organization && @.id==${orgId})]`
    );

  if (networkId)
    requestUrl.searchParams.append(
      "filter",
      `resourceRelationship[?(@.relationshipType==targets && @.resource.@referredType==Network && @.resource.id==${networkId})]`
    );

  if (coreId)
    requestUrl.searchParams.append(
      "filter",
      `resourceRelationship[?(@.relationshipType==targets && @.resource.@referredType==Core && @.resource.id==${coreId})]`
    );

  return requestUrl;
}

export function mpnManagerRequestUrl({
  path,
  orgId,
  networkId
}: {
  path: string;
  orgId: string;
  networkId: string;
}) {
  const requestUrl = new URL(path, window.location.origin);

  requestUrl.searchParams.append(
    "filter",
    `$[?(@.relatedParty[?(@.@referredType=="Organization" && @.id=="${orgId}")])]`
  );

  requestUrl.searchParams.append(
    "filter",
    `$[?(@.supportingResource[?(@.@referredType=="Network" && @.id=="${networkId}")])]`
  );

  return requestUrl;
}
