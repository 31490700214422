import axios from "axios";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { useMutation as useReactQueryMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { Loading } from "@src/Components/Loading/Loading";
import { ModalCloseOnButton } from "@src/Components/Modal/Modal";
import { ConfigField } from "@src/Devices/CloudResource/cloudResourceUtils";
import { ChartConfigField } from "@src/generated/graphql";
import { useOrgCtx } from "@src/Hooks/orgCtx";
import { useToggle } from "@src/Hooks/toggle";
import { queryClient } from "@src/reactQueryClient";

import { configFieldsSort } from "../Infrastructure/ResourceChartSelector";
import { headerNetworksKey, QueryCallbacks } from "../mpnRequests";
import { useFetchNetworkDetails } from "../networks";
import {
  NetworkForm,
  NetworkFormInner,
  serialiseNetworkForm,
  SuccessModal
} from "./NetworkFormComponents";

function initEditConfigFields(fields: ChartConfigField[]): ConfigField[] {
  return [...(fields || [])].sort(configFieldsSort).map(field => {
    const { type, value, required, redacted } = field;
    return {
      ...field,
      type: field.label === "infraSiteId" ? "site-selector" : field.type,
      value: type === "boolean" ? value === "true" : value || "",
      initialValue: value,
      open: !redacted,
      selected: required && !redacted
    };
  });
}

function editNetwork(networkId: string, network: object, { onComplete }: QueryCallbacks) {
  return axios
    .patch(
      `/mobile-networks/network/tmf-api/resourceInventoryManagement/v4/resource/${networkId}`,
      network
    )
    .then(response => {
      onComplete();
      return response.data;
    });
}

export function EditNetworkForm() {
  const navigate = useNavigate();
  const orgCtx = useOrgCtx();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const { network, loading } = useFetchNetworkDetails();

  const { state: showSuccess, toggle: toggleSuccess } = useToggle();

  const initialValues: NetworkForm = {
    id: network?.uid,
    fields: initEditConfigFields(network?.config || [])
  };

  const onCompleteEdit = useCallback(() => {
    queryClient.refetchQueries({ queryKey: [headerNetworksKey] });
    queryClient.refetchQueries({ queryKey: [orgCtx, network?.uid, "network-details"] });
    toggleSuccess();
  }, [network, orgCtx, toggleSuccess]);

  const {
    data,
    error: editNetworkError,
    mutate: editNetworkMutation
  } = useReactQueryMutation(["edit-network", network?.uid], (editedNetwork: object) =>
    editNetwork(network?.uid, editedNetwork, {
      onComplete: onCompleteEdit
    })
  );

  if (loading) return null;
  return (
    <>
      <Formik<NetworkForm>
        initialValues={initialValues}
        onSubmit={(values, formik) => {
          try {
            const serialisedValues = serialiseNetworkForm(values, network.chartversion, orgCtx);
            editNetworkMutation(serialisedValues);
            formik.setSubmitting(false);
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {formikProps =>
          formikProps.isSubmitting ? (
            <Loading />
          ) : (
            <>
              <NetworkFormInner
                goBack={goBack}
                submitErrors={editNetworkError as Error}
                isEdit
                {...formikProps}
              />
              <ModalCloseOnButton show={showSuccess}>
                <SuccessModal id={data?.id} isEdit />
              </ModalCloseOnButton>
            </>
          )
        }
      </Formik>
    </>
  );
}
